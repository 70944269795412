import webSee from '@yaww-websee/core';
import performance from '@yaww-websee/performance';
import recordscreen from '@yaww-websee/recordscreen';
import { getConfigByEnv } from '@/util/index';

export default (app: any) => {
  const { reportUrl } = getConfigByEnv();
  app.use(webSee, {
    dsn: reportUrl, // 上报的地址
    apply_id: 'yaww-m', // 项目唯一的id
    repeatCodeError: true, // 开启错误上报去重，重复的代码错误只上报一次
    silentWhiteScreen: false, // 开启白屏检测
    client_type: 20, // m端
  });

  // 注册性能检测插件
  webSee.use(performance, {});
  // 注册页面录屏插件，设置单次录屏时长为20s，默认是10s
  webSee.use(recordscreen, { recordScreentime: 20 });
};
