// 获取路径上的参数
interface URL_PARAMS {
  [propName: string]: string;
}

export function getQueryObject(url?: string) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj: URL_PARAMS = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

export function getConfigByEnv(): any {
  const env = process.env.BUILD_ENV;
  let prefix = '';
  let reportUrl = '';
  switch (env) {
    case 'dev':
      prefix = 'https://dev-gateway.yuaiweiwu.com';
      reportUrl = 'https://dev-i.yuaiweiwu.com/gs.gif';
      break;
    case 'prod':
      prefix = 'https://gateway.yuaiweiwu.com';
      reportUrl = 'https://i.yuaiweiwu.com/gs.gif';
      break;
    case 'local':
      prefix = '';
      reportUrl = 'https://dev-i.yuaiweiwu.com/gs.gif';
      break;
    default:
      prefix = 'https://gateway.yuaiweiwu.com';
      reportUrl = 'https://dev-i.yuaiweiwu.com/gs.gif';
      break;
  }
  return {
    prefix,
    reportUrl,
  };
}

export function objectToQuery(obj: any, prefix: any) {
  if (typeof obj !== 'object') return '';
  const attrs = Object.keys(obj);
  return attrs.reduce((query, attr, index) => {
    if (index === 0 && !prefix) query += '?';
    if (typeof obj[attr] === 'object') {
      const subPrefix = prefix ? `${prefix}[${attr}]` : attr;
      query += objectToQuery(obj[attr], subPrefix);
    } else {
      if (prefix) {
        query += `${prefix}[${attr}]=${obj[attr]}`;
      } else {
        query += `${attr}=${obj[attr]}`;
      }
    }
    if (index !== attrs.length - 1) query += '&';
    return query;
  }, '');
}
